<!--
 * @Description: 开始配置页面
 * @version: v1.0.0
 * @Author: ladd
 * @Date: 2021-08-27 10:37:00
 * @LastEditors: ladd
 * @LastEditTime: 2021-09-26 16:59:40
-->
<template>
  <div class='startSet' v-loading="loading"  element-loading-text="拼命加载中" element-loading-spinner="el-icon-loading">
    <div class="header">
      <el-steps :active="active" finish-status="success" :align-center="true">
        <el-step title="选择银行"></el-step>
        <el-step title="配置渠道"></el-step>
        <el-step title="配置码样式"></el-step>
      </el-steps>
    </div>
    <div class="content">
      <el-row type="flex" justify="space-around">
        <el-col :span="10">
          <section v-show="active === 0">
            <el-form :model="bankCodeData" ref="bankForm" :rules="providerRules" label-width="140px" class="form-list">
              <el-form-item label="银行类型" prop="bank_type">
                <el-select v-model="bankCodeData.bank_type" placeholder="请选择银行类型" class="_el-select" size="small" @change="changeBankType">
                  <el-option v-for="(item,index) in bankTypeArr" :key="index" :label="item.name"
                    :value="item.bank_type">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="选择银行" prop="bank_id">
                <el-select v-model="bankCodeData.bank_id" filterable clearable  placeholder="请选择银行，支持输入搜索" class="_el-select" size="small">
                  <el-option v-for="(item, index) in bankList" :key="index" :label="item.bank_name"
                    :value="item.bank_id">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="卡类型" prop="card_type">
                <el-select v-model="bankCodeData.card_type" placeholder="请选择卡类型" class="_el-select" size="small">
                  <el-option v-for="(item,index) in cardTypeArr" :key="index" :label="item.name"
                    :value="item.card_type">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="银行码名称" prop="qrcode_name">
                <el-input v-model="bankCodeData.qrcode_name" placeholder="请输入银行码名称" clearable size="small"></el-input>
              </el-form-item>
            </el-form>
          </section>
          <section v-show="active === 1">
            <el-form :model="bankCodeData" ref="channelForm" :rules="providerRules" label-width="140px" class="form-list">
              <el-form-item label="渠道类型" prop="channel_type">
                <el-radio-group v-model="bankCodeData.channel_type">
                  <el-radio :label="item.id" v-for="(item, index) in channelTypeArr" :key="index">{{ item.name }}</el-radio>
                </el-radio-group>
              </el-form-item>
              <section v-if="bankCodeData.channel_type === 1">
                <el-form-item label="样式模板" prop="template_id">
                  <el-select v-model="bankCodeData.template_id" placeholder="请选择码样式模板" class="_el-select" size="small" @change="changetemplateId">
                    <el-option v-for="(item, index) in templateList" :key="index" :label="item.template_name"
                      :value="item.template_id">
                    </el-option>
                  </el-select>
                </el-form-item>
              </section>
              <section v-if="bankCodeData.channel_type === 2">
                <el-form-item label="员工编号" prop="staff_code">
                  <el-input v-model="bankCodeData.staff_code" placeholder="请输入员工编号" clearable size="small"></el-input>
                </el-form-item>
                <el-form-item label="员工姓名" prop="staff_name">
                  <el-input v-model="bankCodeData.staff_name" placeholder="请输入员工姓名" clearable size="small"></el-input>
                </el-form-item> 
                <el-form-item label="样式模板" prop="template_id">
                  <el-select v-model="bankCodeData.template_id" placeholder="请选择码样式模板" class="_el-select" size="small"  @change="changetemplateId">
                    <el-option v-for="item in templateList" :key="'template_id' + item.template_id" :label="item.template_name"
                      :value="item.template_id">
                    </el-option>
                  </el-select>
                </el-form-item>
              </section>
              <section v-if="bankCodeData.channel_type === 3">
                <el-form-item label="商户名称" prop="merchant_name">
                  <el-input v-model="bankCodeData.merchant_name" placeholder="请输入商户名称" clearable size="small"></el-input>
                </el-form-item> 
                <el-form-item label="样式模板" prop="template_id">
                  <el-select v-model="bankCodeData.template_id" placeholder="请选择码样式模板" class="_el-select" size="small"  @change="changetemplateId">
                    <el-option v-for="item in templateList" :key="'template_id' + item.template_id" :label="item.template_name"
                      :value="item.template_id">
                    </el-option>
                  </el-select>
                </el-form-item>
              </section>
            </el-form>
          </section>
          <section v-show="active === 2">
            <el-form :model="bankCodeData" ref="templateForm" :rules="providerRules" label-width="140px" class="form-list">
              <el-form-item label="卡片背景色" :prop="`qrcode_attr_struct.card_bg_color`">
                <el-color-picker v-model="bankCodeData.qrcode_attr_struct.card_bg_color"></el-color-picker> 
              </el-form-item>
              <el-form-item label="主标题" :prop="`qrcode_attr_struct.title`">
                <el-input v-model="bankCodeData.qrcode_attr_struct.title" :minlength="5" :maxlength="10" placeholder="请输入主标题，最少5个字，最多10个字" clearable size="small"></el-input>
              </el-form-item>
              <el-form-item label="主标题字体颜色" :prop="`qrcode_attr_struct.title_font_color`">
                <el-color-picker v-model="bankCodeData.qrcode_attr_struct.title_font_color"></el-color-picker> 
              </el-form-item>
              <el-form-item label="描述" :prop="`qrcode_attr_struct.description`">
                <el-input v-model="bankCodeData.qrcode_attr_struct.description" type="textarea"  placeholder="请输入描述，支持换行显示，每行最少8个字，最多20个字，最多2行" clearable></el-input>
              </el-form-item>
               <el-form-item label="描述字体颜色" :prop="`qrcode_attr_struct.desc_font_color`">
                <el-color-picker v-model="bankCodeData.qrcode_attr_struct.desc_font_color"></el-color-picker> 
              </el-form-item>
              <el-form-item label="二维码logo" prop="display_logo">
                <el-radio-group v-model="bankCodeData.display_logo">
                  <el-radio :label="item.id" v-for="item in displayLogoArr" :key="item.id">{{ item.name }}</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="码编号字体颜色" :prop="`qrcode_attr_struct.no_font_color`">
                <el-color-picker v-model="bankCodeData.qrcode_attr_struct.no_font_color"></el-color-picker> 
              </el-form-item>
            </el-form>
          </section>
        </el-col>
        <el-col :span="10">
          <div class="preview" v-if="active === 2">
            <div class="preWrap">
              <h5 class="preTitle">银行码图片预览</h5>
              <div class="preContent" id="qrcodeImg">
                <div class="preHeader">
                   <el-image
                    :src="bankCodeData.qrcode_attr_struct.logo | to-https"
                    fit="contain" class="bankLogo"></el-image>
                    <el-image
                    :src="imgUrl.wxLogo"
                    fit="contain" class="wxLogo"></el-image>
                </div>
                <div class="preInfo" :style="{backgroundColor: bankCodeData.qrcode_attr_struct.card_bg_color || '#13b32f'}">
                  <div>
                    <h6 class="preInfo_t" :style="{color: bankCodeData.qrcode_attr_struct.title_font_color || '#fff' }">{{bankCodeData.qrcode_attr_struct.title || '微信支付首绑送微信立减金'}}</h6>
                    <pre class="preInfo_d" :style="{color: bankCodeData.qrcode_attr_struct.desc_font_color || '#fff' }">{{bankCodeData.qrcode_attr_struct.description || '首次绑卡'}}</pre>
                  </div>
                  <div class="qrcode_warp" :style="{backgroundImage: 'url('+imgUrl.qrLogo+')'}">
                    <div class="qrcodeBox" ref="qrcodeBox">
                      <el-image
                      v-if="bankCodeData.display_logo === 1"
                      :src="bankCodeData.qrcode_attr_struct.qrcode_logo | to-https"
                      fit="contain" class="bankIcon"></el-image>
                    </div>
                   
                  </div>
                  <div class="code" :style="{color: bankCodeData.qrcode_attr_struct.no_font_color || '#fff' }">{{ scene ||'123456789123456789'}}</div>
                </div>
              </div>
            </div>
            <!-- <div class="previewBtn">{{bankType}}</div> -->
          </div>
        </el-col>
      </el-row>
     
    </div>
    <div class="footer">
      <el-button  @click="exitConfig">退出配置</el-button>
      <el-button  @click="before()" v-if="active !== 0">上一步</el-button>
      <el-button  type="primary" @click="next()">{{active === 2? '确定生成': '下一步'}}</el-button>
    </div>
  </div>
</template>

<script>
import { Message } from 'element-ui';
import QRCode from 'qrcodejs2'
// import domtoimg from '@/utils/dom-to-img'
import domtoimg from 'dom-to-image'
import { bankTypeArr, cardTypeArr, channelTypeArr, displayLogoArr } from '@/utils/constant'
import * as bankCodeSet from '@/api/bankCodeSet'
import * as Public from '@/api/public'
export default {
  //import引入的组件需要注入到对象中才能使用
  name: 'StartSet',
  components: {},
  data() {
    //这里存放数据
    return {
      loading: false,
      imgUrl: {
        bankLogo: require('@/assets/images/u1412_03.png'),
        wxLogo: require('@/assets/images/u1412_06.png'),
        qrLogo: require('@/assets/images/u1412_11.png')
      },
      active: 0,
      bankCodeData: {
        description:'hello world',
        channel_type: 1,
        display_logo:1,
        qrcode_attr_struct: {}
      },
      bankList: [],
      templateList: [],
      bankTypeArr: bankTypeArr,
      cardTypeArr: cardTypeArr,
      channelTypeArr: channelTypeArr,
      displayLogoArr: displayLogoArr,
      providerRules: {
        bank_type: [{ required: true, message: '请选择银行类型', trigger: 'change' }],
        bank_id: [{ required: true, message: '请选择银行', trigger: 'change' }],
        card_type: [{ required: true, message: '请选择卡类型', trigger: 'change' }],
        qrcode_name: [{ required: true, message: '请输入银行码名称', trigger: 'blur' }],
       
        channel_type: [{ required: true, message: '请选择渠道类型', trigger: 'change' }],
        template: [{ required: true, message: '请选择码样式模板', trigger: 'change' }],
        staff_code: [{ required: true, message: '请输入员工编号', trigger: 'blur' }],
        staff_name: [{ required: true, message: '请输入员工姓名', trigger: 'blur' }],
        merchant_name: [{ required: true, message: '请输入商户名称', trigger: 'blur' }],
        template_id: [{ required: true, message: '请选择样式模板', trigger: 'change' }],

        // title: [{ required: true, message: '请输入主标题', trigger: 'blur' }],
        // description: [{ required: true, message: '请输入描述', trigger: 'blur' }],
        display_logo: [{ required: true, message: '请选择二维码是否显示', trigger: 'change' }],
        qrcode_attr_struct: {
          title: [{ required: true, message: '请输入主标题', trigger: 'blur' }],
          description: [{ required: true, message: '请输入描述', trigger: 'blur' }], 
          card_bg_color: [{ required: true, message: '请选择卡片背景色', trigger: 'change' }],
          title_font_color: [{ required: true, message: '请选择主标题字体颜色', trigger: 'change' }],
          desc_font_color: [{ required: true, message: '请选择描述字体颜色', trigger: 'change' }],
          no_font_color: [{ required: true, message: '请选择编码字体颜色', trigger: 'change' }]
        }
      },
      scene: '',
    };
  },
  //监听属性 类似于data概念
  computed: {
    // 银行码样式
    previewStyle: function(){

    },
    
    
    // // 银行type
    bankType: function(){ 
      if (this.active === 2) {
        const index = this.bankList.map(item => item.bank_id).indexOf(this.bankCodeData.bank_id);
        const cardTypeIndex = this.cardTypeArr.map(item => item.card_type).indexOf(this.bankCodeData.card_type)
        return this.bankList[index].enum_str + this.cardTypeArr[cardTypeIndex].enum_str
      }
      
    }
    
  },
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    // 初始化
    getTemplateList: function(){
      // 获取模板数据
      this.loading = true;
      bankCodeSet.templateList({bank_id: this.bankCodeData.bank_id,page_size: 999}).then(res => {
        this.loading = false;
        this.templateList = res.list
      })
    },
    // 选择银行类型，拉取银行列表
    changeBankType: function(){
      this.loading = true;
      this.$set(this.bankCodeData, 'bank_id', '');
      // bankCodeSet.bankList({bank_level: this.bankCodeData.bank_type, page_size: 999}).then(res => {
      //   this.loading = false;
      //   this.bankList = res.list
      // })
      Public.ownerBank({bank_level: this.bankCodeData.bank_type, page_size: 999}).then(res => {
        this.loading = false;
        this.bankList = res.list
      })
    },
    // 选择模板
    changetemplateId: function(){
      const templateId = this.bankCodeData.template_id;
      const index = this.templateList.map(item => item.template_id).indexOf(templateId);
      this.$set(this.bankCodeData, 'qrcode_attr_struct', this.templateList[index].template_attr_struct)
    },
    // 获取场景值
    getSence: function(){
      const {qrcode_name, bank_id, card_type, template_id} = this.bankCodeData
      this.loading = true;
      bankCodeSet.genScene({qrcode_name, bank_id, card_type, template_id}).then(res => {
        this.loading = false;
        this.scene = res.scene;
        // this.createQrcode();
      })
    },
    // 生成二维码
    createQrcode: function(){
      // console.log(this.$refs['qrcodeBox']);
      new QRCode(this.$refs['qrcodeBox'], {
        width: 116,
        height: 116,
        text: `weixin://wxpay/bindurl?bank_type=${this.bankType}&scene=${this.scene}` || '未生成链接，请稍后',
        render: 'canvas',
        colorDark: '#000000',
        colorLight: '#ffffff'
        // correctLevel: QRCode.CorrectLevel.H
      })
    },

    // dom转成img
    domToImg: function(){
      let node = document.getElementById('qrcodeImg')
      // const option  = {
			// 		width: 1024, // 高度宽度自行设定
			// 		height: 1448,
      //     // scale: 4 
			// }
      return domtoimg.toPng(node).then((dataUrl) => {
      // return domtoimg.toPng(node, {...option}).then((dataUrl) => {
        return dataUrl;
      }).catch((error) =>{
        Message.error(error)
      })
    },
    // 退出配置
    exitConfig: function(){
      this.active = 0
      this.$emit('toConfig', 0)
    },
    // 上一步
    before: function() {
      if (this.active-- < 0) this.active = 0;
    },
    // 下一步
    next: function() {
      if (this.active === 0) {
         this.$refs['bankForm'].validate((valid) => {
          if (valid) {
            // 通过验证
            if (this.active++ > 2) this.active = 0;
            this.getTemplateList();
          } else {
          // 未通过验证
            return false
          }
        })
      } else if(this.active === 1){
        this.$refs['channelForm'].validate((valid) => {
          if (valid) {
            // 通过验证
            if (this.active++ > 2) this.active = 0;
            this.getSence()
          } else {
          // 未通过验证
            return false
          }
        })
      } else {
        this.$refs['templateForm'].validate((valid) => {
          if (valid) {
            this.$set(this.bankCodeData, 'scene', this.scene)
            this.domToImg().then(res=>{
              this.$set(this.bankCodeData, 'qrcode_base64', res)
              this.loading = true;
              bankCodeSet.bankQrcodes( this.bankCodeData).then(res => {
                this.loading = false;
                if(res.id) {
                  this.$router.push({path:'/BankCodeDetail', query: {id: res.id}})
                }
              })
            })
            
            // 通过验证
           
          } else {
          // 未通过验证
            return false
          }
        })
      }
      
    },

  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    
  },
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang='scss' scoped>
//@import url(); 引入公共css类
$pre-width: 1024px;
$pre-heght: 1448px;
$width: 313px;
$heght: 442px;
.form-list {
  width: 500px;
  ._el-select{
    width: 100%;
  }
}
 
.startSet{
  padding-top: 50px;
  .header{
    margin: 30px auto 60px;
    width: 60%;
  }
  .preview{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    .preWrap{
      width: 515px;
      height: 586px;
      background: rgba(247, 247, 247, 1);
      display: flex;
      flex-direction: column;
      align-items: center;
      .preTitle{
        text-align: center;
        line-height: 48px;
      }
      .preContent{
        width: $width;
        height: $heght;
        background: #fff;
        box-sizing: border-box;
       
      }
      .preHeader{
        width: 100%;
        height: 207px / $pre-heght * $heght;
        display: flex;
        align-items: center;
        justify-content: center;
        .bankLogo{
          width: 477px / $pre-width * $width;
          height: 66px / $pre-heght * $heght;
          margin-right: 40px / $pre-width * $width;
        }
        .wxLogo{
          width: 312px / $pre-width * $width;
          height: 60px / $pre-heght * $heght;
        }
      }
      .preInfo{
        width: 100%;
        height: $heght - (207px / $pre-heght * $heght);
        padding: 94px / $pre-heght * $heght 0 40px / $pre-heght * $heght;
        display: flex;
        flex-direction: column;
        // justify-content: space-between;
        align-items: center;
        box-sizing: border-box;
        .preInfo_t{
          text-align: center;
          font-size: 20px;
          line-height: 1;

        }
        .preInfo_d{
          margin-top: 32px / $pre-heght * $heght;
          margin-bottom: 70px / $pre-heght * $heght;
          text-align: center;
          font-size: 12px;
          line-height: 18px;
          font-family: Avenir, Helvetica, Arial, sans-serif;
        }
        .qrcode_warp{
          width: 590px / $pre-width * $width;
          height: 700px / $pre-heght * $heght;
          background-repeat: no-repeat;
          background-size: 590px / $pre-width * $width 700px / $pre-heght * $heght;
          display: flex;
          justify-content: center;
          .qrcodeBox{
            margin-top: 19px;
            width: 116px;
            height: 116px;
            position: relative;
            .bankIcon{
              position: absolute;
              left: 50%;
              top: 50%;
              transform: translate(-50%, -50%);
              width: 28px;
              height: 28px;
            }
          }
         
        }
        .code{
          margin-top: 24px;
          font-size: 12px;
        }
      }
    }
    .previewBtn{
      margin: 20px;
      color: #22ac38;
      cursor: pointer;
    }
    
  }
  
  .footer{
    margin-top: 100px;
    display: flex;
    justify-content: center;
  }
}
</style>